@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/work-sans.regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Work Sans Bold';
  font-style: normal;
  src: url('/assets/fonts/WorkSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans ExtraBold';
  font-style: normal;
  src: url('/assets/fonts/WorkSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans Light';
  font-style: normal;
  src: url('/assets/fonts/WorkSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans Medium';
  font-style: normal;
  src: url('/assets/fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans SemiBold';
  font-style: normal;
  src: url('/assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
}

body {
  background: url(/assets/images/background-landing-page.jpg) !important;
  /* background: #fdead7; */
  font-family: 'Work Sans Medium' !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #afaaaa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2691e;
  border-radius: 2px;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

button:hover {
  opacity: 0.8;
}

.signin_with_google {
  background-color: white;
  border: 1px solid lightgray;
  width: 200px;
  padding: 10px 15px;
  transition: 0.3s ease;
  cursor: pointer;
}

.signin_with_google:hover {
  background-color: #eaeaea;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dot-elastic {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #331103;
  color: #331103;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -25px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #331103;
  color: #331103;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 25px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #331103;
  color: #331103;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.loading-data-wrapper {
  z-index: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}
